/*
* 업무구분: 신탁>고객상품>고객투자자현황분석>고객성향분석
* 화 면 명: MSPTS105M
* 화면설명: [7010] 고객성향분석 결과조회
* 작 성 일: 2023.02.27
* 작 성 자: 지재호
* 파 일 명: TFCP11050
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="고객성향분석결과" :topButton="true">
    
    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label> 실명번호 종류 </label>
              <mo-dropdown class="mw150" 
                :items="cmb_rcnoKindTc" 
                v-model="ds_search.rcnoKindTc" 
                ref="cmb_rcnoKindTc"
              />
            </div>
            <div class="wrap-input row ml-2">
              <label class="emphasis"> 실명번호 </label>
              <mo-text-field placeholder="앞 6자리" class="w80" 
                v-model="rcnoOne"
                maxlength="6"
                type="number"
                @keyup="[fn_SearchValid($event, 'edt_rcnoOne'),fn_validErrRemoveInput($event, 'edt_rcnoOne')]" 
                @paste="fn_SearchValid($event, 'P')"
                ref="edt_rcnoOne" 
              />
              <m-trans-key-input placeholder="뒤 7자리" class="w80"
                v-if="isMobile"
                v-model="rcnoTwo" 
                type="numberMax7"
                dialog="Y"
                start="1" 
                end="-1"
                :isClear="lv_isClear"
                @masked-txt="fn_SetMaskedTxt"         
                ref="edt_rcnoTwo" 
              />
              <mo-text-field placeholder="뒤 7자리" class="w80"
                v-else
                type="password"
                v-model="rcnoTwo" 
                mask="#######" 
                numeric
                @keyup="[fn_SearchValid($event, 'edt_rcnoTwo'),fn_validErrRemoveInput($event, 'edt_rcnoTwo')]" 
                ref="edt_rcnoTwo" 
              />
            </div>
            <div class="wrap-input row">
              <label> 고객명 </label>
              <mo-text-field class="form-input-name" v-model="cnm" disabled clearable placeholder=" " />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button class="btn-clear" @click="fn_init"> 초기화 </mo-button>
              <mo-button primary class="btn-inquiry" @click="fn_searchList"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title"> 응답결과 </h2>
        </div>
        <div class="wrap-desc bg-blue">
          <p>
            <b class="user-name"> {{cnm}} </b> 고객님의 투자정보를 분석한 결과 <br/>
            {{yyyy}}년 {{mm}}월 {{dd}}일 현재 <span class="result"> {{bdytxCntn}} </span> 에 속합니다.
          </p>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title row">
          <h2 class="table-title"> 투자성향 </h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type num-type th-row-gray">
            <tbody>
              <tr>
                <th> 분석일자(회차) </th>
                <th> 투자성향 </th>
                <th> 점수 </th>
                <th> 특징 </th>
              </tr>

              <tr 
                v-for="(item, idx) in rstDetail"
                :key="idx" 
              > 
                <td> {{item.procDt}} <br/> ({{item.cnt}}회차) </td>
                <td> {{item.bdytxCntn}} </td>
                <td> {{item.quesTotScr}} </td>
                <td> {{item.rmk500}}</td>
              </tr>
              
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-signature column">
          <p class="date"> <span class="year">{{tdYyyy}}</span> 년 <span class="month">{{tdMm}}</span> 월 <span class="day">{{tdDd}}</span> 일 </p>
        </div>
        <div class="wrap-desc bottom">
          <p>
            상기 투자정보 분석 결과 보고서는 고객님이 작성하신 투자자정보 확인서를 통해 산출되었습니다. 작성된 투자성향 결과는 고객님의 현재 상황을 고려한 것으로 향후 상황이 현재 시점과 달라지는 경우에는 투자권유인(플라자FP)과 상의하시기 바랍니다.
          </p>
        </div>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" :disabled="enabledList.btn_confirm==false" @click="fn_confirm"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

    <!-- popup -->
    <mo-modal class="fts-modal small" ref="modal1">
      <template>
        정상등록 되었습니다
      </template>
      <template slot="action">
        <mo-button primary @click="closeModal(1)">확인</mo-button>
      </template>
    </mo-modal>

    <mspLoading v-if="lv_wait"
      style="z-index:20000 !important"
    ></mspLoading>
  </ur-page-container>
</template>
<script>

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                    *
   ***********************************************************************************/
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'    
  
  import MspLoading from "@/ui/_cmm/components/MspLoading"

  /***********************************************************************************
   * DataSet 영역	                                                                   *
   ***********************************************************************************/
  const defaultDsDetail = {  
    bizDate : '',
    procDt : '',
    yyyy : '',
    mm : '',
    dd : '',
    cnm : '',
    rcno : '',
    rcnoKindTc: '01',
    rcnoKindTcNm : '',
    quesTotScr : '',
  }

  const defaultDsDetail1 = {  
    bizDate : '',
    procDt : '',
    yyyy : '',
    mm : '',
    dd : '',
    cnm : '',
    rcno : '',
    rcnoKindTc : '',
    rcnoKindTcNm : '',
  }

  const defaultDsSearch = {
    rcnoKindTc: '01',
    rcno: '',
    bizDate: '',
    csId: '',
  }


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS105M",
    screenId: "MSPTS105M",
    components: {
      'ts-header': TSHeader,
      'ts-alert-popup': TSAlertPopup,
      'mspLoading': MspLoading,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      //계좌정보
      if ( !TSCommUtil.gfn_isNull(this.$route.params.processGb) ){
        this.processGb = this.$route.params.processGb;
      }
      if ( !TSCommUtil.gfn_isNull(this.$route.params.tacno) ){
        this.tacno = this.$route.params.tacno;
      }
      if ( !TSCommUtil.gfn_isNull(this.$route.params.bnkbSeq) ){
        this.bnkbSeq = this.$route.params.bnkbSeq;
      }
      if ( !TSCommUtil.gfn_isNull(this.$route.params.accSeq) ){
        this.accSeq = this.$route.params.accSeq;
      }
      //계좌정보 end.
      
      this.ds_detail = Object.assign({}, defaultDsDetail)
      this.ds_detail1 = Object.assign({}, defaultDsDetail1)
      this.ds_search = Object.assign({}, defaultDsSearch)

      // console.log('--------------test-----------')
      // console.log(this.$route.params)
      // if ( this.$route.params.reportParam !== undefined ) {
      //   const parsObj = JSON.parse( this.$route.params.reportParam )
      //   console.log(parsObj)
      //   console.log(this.$route.params)

      //   // TDOO : 이부분은 좀더 확인해봐야함
      //   this.ds_search.rcnoKindTc = parsObj.rcnoKindTc
      //   this.ds_search.rcno = parsObj.rcno
      //   this.modelRcno = parsObj.rcno
      //   // this.ds_search.csId = 'rcnoKindTc'
      //   this.fn_searchList();
      // }

      this.$nextTick(()=>{
        if ( !TSCommUtil.gfn_isNull(this.$route.params.rcnoKindTc) ) {
          this.ds_search.rcnoKindTc = this.$route.params.rcnoKindTc
        }
        if ( !TSCommUtil.gfn_isNull(this.$route.params.rcno) ) {
          let t_rcnoOne = this.$route.params.rcno.substr(0,6)
          let t_rcnoTwo = this.isMobile ? '' : this.$route.params.rcno.substr(6,7)
          this.rcnoOne = t_rcnoOne
          this.rcnoTwo = t_rcnoTwo

          if ( this.isMobile )         
            this.fn_AlertPopup(0,{ content : '실명번호 뒷자리를 입력해주시길 바랍니다.'} )
          else // 모바일이 아닐 경우 자동검색, 모바일일 경우 무조건 보안패드로 입력 받는다
            this.fn_searchList()
        }
      })

      this.lv_serverType = process.env.NODE_ENV     
    },
    mounted() {
      //this.fn_afterFormOnload() // 
      this.fn_comCode()
      this.fn_init()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_wait: false,
        lv_serverType: '',
        // 공통 객체
        pHeaderObj: {          
          title: '고객성향분석결과',
          type: 'custIvtrPrpnsAnly',
          step: 2,
        },
        // 보안키패드 관련
        isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
        isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
        lv_isClear: false,        // 보안키패드 초기화 
        lv_masked_rcnoTwo: '',    // 보안키패드 치환값 ****

        lv_isProcess: this.getStore('tsStore').getters.getState.isProcess,
        lv_basInfo: this.getStore('tsStore').getters.getBasInfo.data,
        enabledList: {
          btn_confirm: false
        },
        ds_detail : Object.assign({}, defaultDsDetail),
        ds_detail1: Object.assign({}, defaultDsDetail1),
        ds_search : Object.assign({}, defaultDsSearch),

        // 팝업 객체
        pAlertPopupObj: {}, // 공통 객체

        rcnoOne: '', // 실명번호 첫번째
        rcnoTwo: '', // 실명번호 두번째

        // EAI(PO) 호출 객체
        eaiCommObj: TSCommUtil.gfn_eaiCommObj(),

        // 공통코드 
        cmb_rcnoKindTc: [], // 실명번호 종류

        rstDetail: [], // 조회후 리턴값
        netxtFunc: '',          // TSInfoUtil.commReportInfo 에 콜백함수
        // ds_detail: {}, 
        //계좌정보(MSPTS200M)
        processGb: '',    //업무구분: 1.신규 2.추가입금 3.변경
        tacno: '',        //종합계좌번호
        bnkbSeq: '',      //통장일련번호
        accSeq: '',       //계좌일련번호
        //계좌정보(MSPTS200M) End.
        csId: '',         // 해당 실명번호 csId
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // 실명번호 두번째 검증 비교할값
      valid_rcnoTwo() {
        if ( this.isMobile ) { // 보안키패드 
          return this.lv_masked_rcnoTwo
        } else { // 보안키패드가 아닌경우
          return this.rcnoTwo
        }
      },

      rcnoKindTcList() {
        let rtn = [];
        rtn.push({value: '01', text: '주민등록번호'});
        rtn.push({value: '02', text: '여권번호'});
        return rtn;
      },

      cnm: {
        set: function() {
        },
        get: function() {
          if ( this.rstDetail.length > 0 && this.rstDetail[0].cnm )
            return this.rstDetail[0].cnm
          else 
            return ''
        }
      },

      yyyy: {
        set: function() {
        },
        get: function() {
          if ( this.rstDetail.length > 0 && this.rstDetail[0].yyyy )
            return this.rstDetail[0].yyyy
          else 
            return ''
        }
      },

      mm: {
        set: function() {
        },
        get: function() {
          if ( this.rstDetail.length > 0 && this.rstDetail[0].mm )
            return this.rstDetail[0].mm
          else 
            return ''
        }
      },

      dd: {
        set: function() {
        },
        get: function() {
          if ( this.rstDetail.length > 0 && this.rstDetail[0].dd )
            return this.rstDetail[0].dd
          else 
            return ''
        }
      },

      bdytxCntn: {
        set: function() {
        },
        get: function() {
          if ( this.rstDetail.length > 0 && this.rstDetail[0].bdytxCntn )
            return this.rstDetail[0].bdytxCntn
          else 
            return ''
        }
      },
      
      tdYyyy(){
        let today = TSCommUtil.fn_strToday()
        return today.substr(0,4)
      },

      tdMm(){
        let today = TSCommUtil.fn_strToday()
        return today.substr(4,2)
      },

      tdDd(){
        let today = TSCommUtil.fn_strToday()
        return today.substr(6,2)
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_validErrRemoveInput
       * 설명       : input, mo-text-field 컴포넌트의 css error class 삭제
       ******************************************************************************/
      fn_validErrRemoveInput (event, el) {
        const value = event.currentTarget.value
        const _el = this.$refs[el].$el
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(value) ) )
          _el.classList.remove('error')
      },

      /******************************************************************************
      * Function명 : fn_SetMaskedTxt
      * 설명       : 보안키패드 체크
      ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_rcnoTwo = val
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(val) ) )
          this.$refs['edt_rcnoTwo'].$el.classList.remove('error')
      },

      /******************************************************************************
       * Function명 : fn_confirm
       * 설명       : 확인버튼 이벤트
       *              고객성향분석 페이지는 프로세스 일경우 팝업이라 마지막이 아니고, 
       *              개별일 경우 해당페이지는 마지막이 됨
       ******************************************************************************/
      async fn_confirm () {
        let lv_Vm = this
        let t_type    = 0
        
        // 실명번호를 이용한 csId 조회 
        let cdiInfo = await TSInfoUtil.gfn_getCdiInfo(lv_Vm, this.ds_search)
        this.csId = TSCommUtil.gfn_isNull( cdiInfo.csId ) ? '' : cdiInfo.csId

        // 종합계좌번호 셋팅
        if ( TSCommUtil.gfn_isNull( this.tacno ) ) { // 없는 경우는 개별접근, 프로세스일 경우에는 신규신청 (MSPTS200M) 에서 값을 보내 라우터 파람으로 셋팅함
          let lv_tacno = await TSInfoUtil.gfn_getTacno(lv_Vm, this.ds_search)
          this.tacno = TSCommUtil.gfn_isNull( lv_tacno ) ? '' : lv_tacno
        }

        let t_popupObj = {
          single      : true ,
          confirm     : true,
          content     : '',
          confirmFunc : '',
          confirmData: {
            tacno: lv_Vm.tacno,      // 종합계좌번호
            bnkbSeq: lv_Vm.bnkbSeq,  // 통장일련번호
            accSeq: lv_Vm.accSeq,    // 계좌일련번호
            csId: lv_Vm.csId,        // 정보동의서 수령방법 (메일, 전화번호 정보항목) 설정관련 추가
          },
        }

        if ( this.lv_isProcess ) { // 프로세스일 경우 해당 풀팝업 닫고 해당 호출 메인컴포넌트 search 함수 실행
          
          t_type = 7 // 프로세스 진행 중 마지막 화면이 아닐 경우
          t_popupObj.confirmFunc = lv_Vm.fn_ReportPrint
          this.netxtFunc = function (){
            lv_Vm.$router.push({ name: 'MSPTS200M',
              params: {
                processGb : lv_Vm.processGb,
                tacno : lv_Vm.tacno,
                bnkbSeq : lv_Vm.bnkbSeq,
                accSeq : lv_Vm.accSeq,
              } })
          }
          
          // t_popupObj.confirmFunc = function(){
          //   lv_Vm.fn_ReportPrint()
          //   lv_Vm.$router.push({ name: 'MSPTS200M', params: {} })
          //   /*** 프로세스 이벤트 구문 ****/ 
          //   // let args = {}
          //   // lv_Vm.$router.push({ name: 'MSPTS203M' , params: { pushParam: JSON.stringify(args) } })
          // }
          
          t_popupObj.contentTitle = '고객성향등록 완료'
          t_popupObj.content = ['고객성향분석이 완료되었습니다.' , '신규신청화면으로 이동합니다']
          
        } else { // 개별 페이지 이벤트
          t_type = 9
          t_popupObj.confirmFunc = lv_Vm.fn_ReportPrint          

          // t_popupObj.confirmFunc = function(){
          //   lv_Vm.fn_ReportPrint()
          // }
          t_popupObj.content = ['고객성향분석 조회가 완료되었습니다.']
          
        }
        lv_Vm.fn_AlertPopup(t_type, t_popupObj)

        // this.enabledList.btn_confirm = false
        // this.fn_AlertPopup(0 ,{ 
        //   content     : '고객성향분석 조회가 완료되었습니다.',
        //   confirm     : true,
        //   confirmFunc : this.fn_ReportPrint,
        //   single      : true,
        // })
      },
      
      /******************************************************************************
       * Function명 : fn_SearchValid
       * 설명       : 필수값 검증 후 조회 
       ******************************************************************************/
      fn_SearchValid (event, flag) {
        console.log('fn_SearchValid.....')

        let t_rcno
        if( flag == 'P' ) {
          t_rcno = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,13)
          let t_rcnoOne = t_rcno.substr(0,6)
          let t_rcnoTwo = this.isMobile ? '' : t_rcno.substr(6,7)
          this.rcnoOne = t_rcnoOne
          this.rcnoTwo = t_rcnoTwo

          if( TSCommUtil.gfn_length(t_rcno) == 13 ) {
            if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
              return 
            }
            this.fn_searchList()
          }

        } else if ( flag == 'edt_rcnoOne' ) {
          t_rcno = this.rcnoOne
          if( TSCommUtil.gfn_length(t_rcno) == 6 ) {
            this.$refs['edt_rcnoTwo'].focus()
          }
        } else if ( flag == 'edt_rcnoTwo' ) { 
          t_rcno = this.rcnoTwo
          if( TSCommUtil.gfn_length(t_rcno) == 7 ) {
            if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
              return 
            }
            this.fn_searchList()
          }
        }
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       
       * 버튼 구성 방법 TYPE == 0
       * 1. 확인(클릭시 콜백 없음) : pPopupObj.confirm == true
       * 2. 확인(클릭시 콜백 있음) : pPopupObj.confirm == true && pPopupObj.single == true
       * 3. 확인,취소(클릭시 콜백 있음) : pPopupObj.confirm == true && pPopupObj.single == false
         
       * 버튼 클릭 콜백 함수
       * pPopupObj.confirmFunc : function  // 확인 버튼 클릭 콜백함수
       * pPopupObj.closeFunc : function    // 취소 버튼 클릭 콜백함수
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0 : 
            TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            this.$refs.alertPopup.fn_Open()
            break
          case 7: // 마지막 얼럿창
            TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            this.$refs.alertPopup.fn_Open()
            break
          case 9 : // 마지막 얼럿창
            TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            this.$refs.alertPopup.fn_Open()            
            break
        }
        
      },

      /**********************************************************************************
       * gfn_setInitForm의  callback 함수 : 실질적 form_onload 구현
       **********************************************************************************/
      fn_afterFormOnload ()
      {
        // 데이터셋 row 초기화후 row추가 : row 없으면 입력 불가능.
        
        // this.Div02.form.cmb_rcnoKindTc.setFocus();
        this.fn_comCode();
        // this.fn_commonAfterOnload(false);
        // 초기화 : [확인] 버튼 비활성화 -> 조회할 고객없을 때 활성화 && 수정_정정구분 선택 시 활성화
        // this.Div00.div_btnList.btn_confirm.set_enable(false); // 확인버튼 활성화
        
        // TODO : ?? 확인요망
        // nexacro.getApplication().afrm_BottomFrame.form.sta_callBackMsg.set_text(""); //화면초기화 후 메세지 후 초기화
      } ,

      /**********************************************************************************
       * 화면상의 버튼리스트를 리턴  : 상단 div에 나타낼 버튼 명시(조회/확인(저장)/취소/초기화)
       **********************************************************************************/
      fn_commonBtnList ()
      {
        return "btn_searchList,btn_print,btn_init";
      },

      /**********************************************************************************
       * 초기화  : WFtitleCRUDbtn 의 callback : 폼로드시 호출, 초기화버튼 클릭시 호출
       **********************************************************************************/
      fn_init ()
      {
        this.ds_search = Object.assign({}, defaultDsSearch)
        this.cnm = ''
        this.rstDetail = []
        this.rcnoOne = ''
        this.rcnoTwo = ''
        this.lv_isClear = !this.lv_isClear ? true : false // 보안키패드 초기화
        this.enabledList.btn_confirm = false
        this.cal_bizDate = this.lv_basInfo.busyDate
        this.fn_rmAllErrCss() // error class 전체 삭제
      },

      /***************************************************************************************
       code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
      *****************************************************************************************/
      fn_comCode ()
      {
        /*dataset 항목setting*/
        
        let objDate = TSServiceUtil.fn_strToday()
        
        let year = objDate.substr(0, 4);
        let month = objDate.substr(4, 2);
        let dd = objDate.substr(6, 2);
        // this.alert("dd:" + dd);

        // 현재일자 구하기
        // let strToday = objDate.getFullYear().toString()+  (month.toString().length == 1 ? "0" : "" ) + month.toString() + (objDate.getDate().toString().length == 1 ? "0" : "" )+  objDate.getDate().toString();//this.gfn_getServerTime(sType);

        let param = [
          {
          code : "5104",
          dsName : "ds_cmb5104"
        }
        ];

        // TSCommForm.setCommonCode(param, null);
        
      },

      /***************************************************************************************
        code        : 호출할 공통코드
        dsName      : 호출된 공통코드를 담을 Dataset
        selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
        
        selecttype은 추후 변경될 수 있음.
        this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
      *****************************************************************************************/
      fn_comCode ()
      {
        // 현재일자 구하기
        let objDate = TSServiceUtil.fn_strToday()
        
        let year = objDate.substr(0, 4);
        let month = objDate.substr(4, 2);
        let dd = objDate.substr(6, 2);

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS90S1' 
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
        this.eaiCommObj.params = {'TUKEMK':[
                                    {cId:"510402"},  // cmb_rcnoKindTc 실명종류
                                ]}
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack, this.fn_error)
      }, 

      fn_comCodeCallBack ( res ) {
        for ( let index in res.data.tukemk ) {
            let item = res.data.tukemk[index]
            switch (item.cid) 
            {
              case "5104": // 510402
                if ( item.c == '01' || item.c == '04' ) // 주민번호, 외국인번호만 사용
                  this.cmb_rcnoKindTc.push({value: item.c , text: item.cnm})
                break;
            }
        }
      },

      /**********************************************************************************
       * gfn_setCommonCode의  callback 함수 : 공통코드 조회후 콜백
       **********************************************************************************/
      fn_commonCodeOnload ()
      {
        // this.gfn_setComboHead(dsName, type)
        // type: Combo 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
        // 필요없는 Combo는 생략(선택)
        this.gfn_setComboHead("ds_cmb5104", "S"); //실명번호종류구분코드
      },

      /**********************************************************************************
       * 공통 실명번호 체크
       **********************************************************************************/
      fn_comRcnoCheck ()
      {
        // // 주민등록번호
        // if (this.ds_search.rcnoKindTc == "01") 
        // {
        //   if (this.ds_search.rcno.trim().length != 13) 
        //   {
        //     this.fn_AlertPopup(0,{ content : '주민번호의 형식이 올바르지 않습니다.'} )
        //     // application.afrm_BottomFrame.form.sta_callBackMsg.set_text("주민번호의 형식이 올바르지 않습니다.");
        //     return false;
        //   }
        // }
        // // 외국인등록번호
        // else if (this.ds_search.rcnoKindTc == "04") 
        // {
        //   if (this.ds_search.rcno.trim().length != 13) 
        //   {
        //     this.fn_AlertPopup(0,{ content : '외국인 등록번호의 형식이 올바르지 않습니다.'} )
        //     // application.afrm_BottomFrame.form.sta_callBackMsg.set_text("외국인 등록번호의 형식이 올바르지 않습니다.");
        //     return false;
        //   }
        // }

        let rcnoKindTcContent = '' 
        if ( this.ds_search.rcnoKindTc == "01" ) 
          rcnoKindTcContent = '주민번호의 형식이 올바르지 않습니다.'
        else
          rcnoKindTcContent = '외국인 등록번호의 형식이 올바르지 않습니다.'
        
        if (TSCommUtil.gfn_trim(this.rcnoOne).length != 6 ) 
        {
          // this.fn_AlertPopup(0,{ content : rcnoKindTcContent } )
          // this.$refs['edt_rcnoOne'].focus()
          TSCommUtil.fn_validate( this, [{ content: rcnoKindTcContent, ref:'edt_rcnoOne' }] )
          return false;
        }
        if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length != 7 )
        {
          // this.fn_AlertPopup(0,{ content : rcnoKindTcContent } )
          // this.$refs['edt_rcnoOne'].focus()
          TSCommUtil.fn_validate( this, [{ content: rcnoKindTcContent, ref:'edt_rcnoTwo' }] )
          return false;
        }

        return true;
      },

      /**********************************************************************************
       * 조회 : WFtitleCRUDbtn 의 callback
       **********************************************************************************/
      fn_searchList ()
      {
        this.enabledList.btn_confirm = false
        /*dataset 항목setting*/
        if ( TSServiceUtil.isNull ( this.ds_search.rcnoKindTc ) ) 
        {
          // this.fn_AlertPopup(0,{ content : '실명번호종류를 입력하십시오.' } )
          TSCommUtil.fn_validate( this, [{ content: '실명번호종류를 입력하십시오.', ref:'cmb_rcnoKindTc' }] )
          return;
        }

        if ( TSServiceUtil.isNull ( this.rcnoOne ) ) 
        {
          TSCommUtil.fn_validate( this, [{ content: '실명번호를 입력하십시오.', ref:'edt_rcnoOne' }] )
          return;
        }
        else if ( TSServiceUtil.isNull ( this.rcnoTwo ) ) 
        {
          TSCommUtil.fn_validate( this, [{ content: '실명번호를 입력하십시오.', ref:'edt_rcnoTwo' }] )
          return;
        }
        else 
        {
          let rcnoKindTcContent = '' 
          if ( this.ds_search.rcnoKindTc == "01" ) 
            rcnoKindTcContent = '주민번호의 형식이 올바르지 않습니다.'
          else
            rcnoKindTcContent = '외국인 등록번호의 형식이 올바르지 않습니다.'
         
          if (TSCommUtil.gfn_trim(this.rcnoOne).length != 6 ) 
          {
            // this.fn_AlertPopup(0,{ content : rcnoKindTcContent } )
            // this.$refs['edt_rcnoOne'].focus()
            TSCommUtil.fn_validate( this, [{ content: rcnoKindTcContent, ref:'edt_rcnoOne' }] )
            return;
          }
          if ( TSCommUtil.gfn_trim(this.valid_rcnoTwo).length != 7 )
          {
            // this.fn_AlertPopup(0,{ content : rcnoKindTcContent } )
            // this.$refs['edt_rcnoOne'].focus()
            TSCommUtil.fn_validate( this, [{ content: rcnoKindTcContent, ref:'edt_rcnoTwo' }] )
            return;
          }
          
        }

        // if ( TSServiceUtil.isNull ( this.rcnoOne ) ) 
        // {
        //   // this.fn_AlertPopup(0,{ content : '실명번호를 입력하십시오.' } )
        //   // this.$refs['edt_rcnoOne'].focus();
        //   TSCommUtil.fn_validate( this, [{ content: '실명번호를 입력하십시오.', ref:'edt_rcnoOne' }] )
        //   return;
        // }

        // if ( TSServiceUtil.isNull ( this.rcnoTwo ) ) 
        // {
        //   // this.fn_AlertPopup(0,{ content : '실명번호를 입력하십시오.' } )
        //   // this.$refs['edt_rcnoTwo'].focus();
        //   TSCommUtil.fn_validate( this, [{ content: '실명번호를 입력하십시오.', ref:'edt_rcnoOne' }] )
        //   return;
        // }
        // 1.실명번호종류구분코드+실명번호 검증.
        if (!this.fn_comRcnoCheck()) 
        {
          return;
        }

        /*서비스 호출시 정보 세팅 */
        // sSvcID = "selectTFCP11050";
        // var sURL = "svc::" + sSvcID + ".do";
        // var sInDatasets = "tfcqal=ds_search:";
        // var sOutDatasets = "ds_detail=tfcqalRst ds_detail1=tfcqalRst1";
        // var sArgument = "";
        // var sCallbackFunc = "fn_callBack";
        // /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(sSvcID, sURL, sInDatasets, sOutDatasets, sArgument, sCallbackFunc);

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.trnstId = 'txTSSTS15S1' //selectTFCP11050
        this.eaiCommObj.auth = 'S'
        //this.eaiCommObj.response.push('ds_detail_rst')  
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600044_S'
        // this.eaiCommObj.params = {
        //   bfcer_incmr_sc_cd     : '', // 수익증권소득자구분코드, Length : 3
        //   bfcer_rlnm_no_knd_cd  : '', // 수익증권실명번호종류코드, Length : 2
        //   rlnm_cnf_no_encr      : ''  // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
        // }
        Object.assign(this.eaiCommObj.params, this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)

      },

      /**********************************************************************************
       * gfn_callService의 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      fn_callBack ( res )
      {
        this.rstDetail = []; // 초기화
        if ( res.data.tfcqal.length > 0 && ! TSServiceUtil.isNull ( res.data.tfcqal[0].cnm.trim() ) )
        {
          for (let item of res.data.tfcqal ) {
              if (item.cnt == 0) // 0회차는 제외한다
                continue

              if ( ! TSServiceUtil.isNull ( item.procDt ) ) {
                item.procDt = TSCommUtil.gfn_dateReplace(1, item.procDt)
              } else {
                item.procDt = ''
              }
              this.rstDetail.push(item)
          }
          this.enabledList.btn_confirm = true
        }
        else 
        {
          this.fn_AlertPopup(0,{ content : ['고객정보 미등록 고객입니다.' , '고객등록 후 진행해주시기 바랍니다.']} )
          return;
        }
      },

      /******************************************************************************
       * Function명 : fn_ReportPrint
       * 설명       : 전자서식 호출
       ******************************************************************************/
      async fn_ReportPrint () {
        let lv_Vm = this
        this.ds_search.bizDate = this.lv_basInfo.busyDate

        let formList = []
        // 공통필수: 전자서명 동의서 
        let TS000001 = {formId: 'TS000001'}
        TS000001.params = {
          reportParam: JSON.stringify( { csId: this.csId } ),
          reportUrl: 'selectTFCP10000',
        }   
        formList.push(TS000001)

        let TS000007 = {formId: 'TS000007'} // TFCP11051.mrd | TS000007
        TS000007.params = {
          reportParam: JSON.stringify(this.ds_search),
          reportUrl: 'reportTFCP11050',
        }
        formList.push(TS000007)

        if ( this.lv_isProcess ) {  // 고객성향분석 페이지는 프로세스 일경우 팝업이라 마지막 아님
          TSInfoUtil.commReportInfo (this, formList, this.netxtFunc, false, 'MSPTS105M');
        } else {  // 개별일 경우 해당페이지는 마지막이 됨
          this.getStore('tsStore').dispatch('IS_LAST_START')
          TSInfoUtil.commReportInfo(this, formList) // 고객성향분석 페이지는 프로세스 일경우 팝업이라 마지막 아님
        }
      },

      /******************************************************************************
       * Function명 : fn_rmAllErrCss
       * 설명       : 해당 컴포넌트 error css 삭제
       ******************************************************************************/
      fn_rmAllErrCss () {
        for ( let idx in this.$refs ) {
          if ( this.$refs[idx] != undefined ) {
            this.$refs[idx].$el.classList.remove("error")
          }
        }
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      rcnoOne: function() {
        this.ds_search.rcno = this.rcnoOne.toString() + this.rcnoTwo.toString()
      },
      rcnoTwo: function() {
        this.ds_search.rcno = this.rcnoOne.toString() + this.rcnoTwo.toString()
      },
    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
